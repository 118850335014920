import React from "react";
import {FormattedMessage} from "react-intl";

const API_URL = process.env.REACT_APP_API_PATH;
const REACT_APP_WEBSITE_ID = process.env.REACT_APP_WEBSITE_ID;
const APP_URL = "https://lumos.casino/";
//const APP_URL = "https://www.uaslots.com";


export const DEV_SOCKET_PATH      = process.env.REACT_APP_DEV_SOCKET_PATH || 'https://wsapi.bmakers.site';

export const PROD_SOCKET_PATH     = process.env.REACT_APP_PROD_SOCKET_PATH || `https://wsapi.bmakers.site`;

export const SOCKET_URL           = process.env.REACT_APP_MODE === 'development' ? DEV_SOCKET_PATH : PROD_SOCKET_PATH;

const app_media_size = {
    mobile: 575,
    tablet: 1199,
    web: Infinity,
    medium: [1200,1439]
};

const user_document_types = {
    international_passport: 1,
    national_passport: 2,
    driving_licence: 3,
    bill: 4,
    other: 5,
    selfie: 6,
};

const user_documents_default_type = 5;

const user_document_status_types = {
    1: 'pending',
    2: 'approved',
    3: 'rejected',
};

const user_bonus_status_types = {
    3: 'expired',
    4: 'finished',
    5: 'granted',
    6: 'claimed',
    7: 'converted',
    8: 'declined'
};

const bonus_types = {
    0: <FormattedMessage id='bonus_types_all' />  ,
    1: <FormattedMessage id='bonus_types_depositBonus' /> ,
    2: <FormattedMessage id='bonus_types_sportsBookBonus' />,
    3: <FormattedMessage id='bonus_types_welcome' /> ,
    4: <FormattedMessage id='bonus_types_firstDeposit' />,
    5: <FormattedMessage id='bonus_types_freeSpin' />,
    6: <FormattedMessage id='bonus_types_birthday' />,
    7: <FormattedMessage id='bonus_types_bonusSpin' />,
    8: <FormattedMessage id='bonus_types_registrationFreeSpin' />,
    9: <FormattedMessage id='bonus_types_balanceAdjustment'/>,
    10: <FormattedMessage id='bonus_types_freeBet' />
};

const notif_count_per_page = 10;
const messages_count_per_page = 10;
const live_chat_license = 9501760;

const currencyList = {
    USD: '$',
    EUR: '€',
    RUB: '₽',
    GBP: '£',
    ARS: '$',
    BTC: 'B',
    TRY: '₺',
    KZT: '₸',
    UAH: '₴',
    PLN: 'zł',
    UZS: "so'm"
};

const bannerSections = {
    promotions: 19,
    games: 18
};

export {
    API_URL,
    APP_URL,
    REACT_APP_WEBSITE_ID,
    app_media_size,
    user_document_types,
    user_document_status_types,
    user_documents_default_type,
    user_bonus_status_types,
    bonus_types,
    notif_count_per_page,
    messages_count_per_page,
    live_chat_license,
    currencyList,
    bannerSections
}