import React from "react";
import {NavLink} from "react-router-dom";
import { FormattedMessage } from "react-intl";

import './sidebar-user-actions.scss';
//import NavLink from "../../controls/nav-link";

const SidebarUserActions = (props) => {


    return (
        <div className="buttons_block">
            <div className="inner_block">
                <NavLink exact className="icon_profile" to="/profile/"><FormattedMessage id="profile.title"/></NavLink>
            </div>
            <div className="inner_block">
                <NavLink exact className="icon_gift" to="/profile/bonus/"><FormattedMessage id="promotions"/></NavLink>
            </div>
        </div>
    )
};

export default SidebarUserActions;