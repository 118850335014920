//import { showLogs } from '../config';
import {SOCKET_CONNECTION_STATUS} from '../constants/common';

import socketActions from './socket/actions';
import balanceActions from './balance/actions';
import authActions from './auth/actions';
import {createError} from './socket';
import {SubscribeCodes} from './codes';

function subscribe(socket, emit) {

    // Standart Events ------------------------------------------------------------------------------

    socket.on('connect', event => {
        logs('Websocket connection established');
        emit(socketActions.setConnectionStatus(SOCKET_CONNECTION_STATUS.connected));
    });

    socket.on('disconnect', reason => {
        logs('Websocket diconnected. Trying reconnect...');
        if (reason === 'io server disconnect') {
            socket.connect();
        }
    });

    socket.on('reconnect', event => {
        logs('Websocket reconnect...');
    });

    socket.on('error', error => {
        logs('Connection to websocket failed with error ' + error);
        emit(socketActions.setConnectionStatus(SOCKET_CONNECTION_STATUS.disconnected));
    });

    socket.on('connect_error', event => {
        logs('Socket connection error...');
        emit(socketActions.setConnectionStatus(SOCKET_CONNECTION_STATUS.disconnected));
    });

    // Custom Events --------------------------------------------------------------------------------

    socket.on('update', event => {

        const {type, data} = event;

        if (!data) {
            logs(createError(event), true);
            return;
        }

        const typeID = parseInt(type);

        switch (typeID) {

            case SubscribeCodes.BALANCE:
            //    console.log("data",data);
                if (typeof data.balance !== "undefined") emit(balanceActions.updateBalance(data));
                if (typeof data.casino_bonus_balance !== "undefined") emit(balanceActions.updateBonusBalance(data));
                break;
            // case SubscribeCodes.BALANCE:
            //         if (typeof data.balance !== "undefined") emit(balanceActions.updateBalance(data));
            //         if (typeof data.casino_bonus_balance !== "undefined") emit(balanceActions.updateBonusBalance(data));
            //         break;
            default:
                logs(`Incoming event: Unknown type ${type}`);
        }
    });

    socket.on('logout', () => {
        emit(authActions.userLogout());
    });
}

function logs(message, isError = false) {
    /*if (!showLogs) {
        return;
    }*/

    if (isError) {
        console.error(message);
        return;
    }

    console.log(message);
}

export default subscribe;
