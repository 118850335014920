import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import settingsActions from "../../../redux/settings/actions";
import Masonry from 'react-masonry-css';

import './providers.scss';


const Providers = ({section}) => {

    const dispatch = useDispatch();
    const footerProviders = useSelector(({Settings}) => Settings.get("providers"));
    let    websiteId = process.env.REACT_APP_WEBSITE_ID;

    useEffect(() => {
        dispatch(settingsActions.getBannersAction({
            section : "providers",
            params  : {group_name:"footer-providers", request_from_front: true, website_id: websiteId}
        }));
    }, [dispatch, section, websiteId]);

    const breakpointColumnsObj = {
        default: 11,
        1199: 10,
        959: 8,
        767: 6,
        575: 5,
        479: 4,
        413: 3
    };
    
    const providersList = footerProviders?.map((item,inx) => {
        if (item.link) {
            return(
                <a href={item.link} target='_blank' rel="noopener noreferrer">
                    <img src={item.image_url} title={item.title} alt={item.title} /> 
                </a>
             )
        }
        return (
            <img src={item.image_url} title={item.title} alt={item.title} key={inx}/>
        )   
    });

    return (
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className="footer_providers"
            columnClassName="list_item">
            {providersList}
        </Masonry>
    )
};

export default Providers;