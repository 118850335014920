const actions = {
    GET_CASINO_HISTORY: 'GET_CASINO_HISTORY',
    SET_CASINO_HISTORY: 'SET_CASINO_HISTORY',
    GET_DEPOSIT_HISTORY: 'GET_DEPOSIT_HISTORY',
    SET_DEPOSIT_HISTORY: 'SET_DEPOSIT_HISTORY',
    GET_WITHDRAWALS_HISTORY: 'GET_WITHDRAWALS_HISTORY',
    SET_WITHDRAWALS_HISTORY: 'SET_WITHDRAWALS_HISTORY',
    GET_CASINO_BONUSES: 'GET_CASINO_BONUSES',
    SET_CASINO_BONUSES: 'SET_CASINO_BONUSES',
    GET_CASINO_SPINS: 'GET_CASINO_SPINS',
    SET_CASINO_SPINS: 'SET_CASINO_SPINS',
    SET_FILTERS: 'SET_FILTERS',
    getCasinoHistory: data => {
        return {
            type: actions.GET_CASINO_HISTORY,
            data
        }
    },
    setCasinoHistory: data => {
        return {
            type: actions.SET_CASINO_HISTORY,
            data
        }
    },
    getCasinoBonuses: data => {
        return {
            type: actions.GET_CASINO_BONUSES,
            data
        }
    },
    setCasinoBonuses: data => {
        return {
            type: actions.SET_CASINO_BONUSES,
            data
        }
    },
    getCasinoSpins: data => {
        return {
            type: actions.GET_CASINO_SPINS,
            data
        }
    },
    setCasinoSpins: data => {
        return {
            type: actions.SET_CASINO_SPINS,
            data
        }
    },
    getDepositHistory: data => {
        return {
            type: actions.GET_DEPOSIT_HISTORY,
            data
        }
    },
    setDepositHistory: data => {
        return {
            type: actions.SET_DEPOSIT_HISTORY,
            data
        }
    },
    getWithdrawalsHistory: data => {
        return {
            type: actions.GET_WITHDRAWALS_HISTORY,
            data
        }
    },
    setWithdrawalsHistory: data => {
        return {
            type: actions.SET_WITHDRAWALS_HISTORY,
            data
        }
    },
    setFilters: data => {
        return {
            type: actions.SET_FILTERS,
            data
        }
    },
};

export default actions;