import React, {useEffect} from "react";
import { FormattedMessage } from "react-intl"; 
import {useDispatch, useSelector} from "react-redux";
import settingsActions from "../../../../redux/settings/actions";

const AppsVerify =({section})=>{

        const dispatch = useDispatch();
        const footerVerify = useSelector(({Settings}) => Settings.get("verify"));
        const footerCompanies = useSelector(({Settings}) => Settings.get("footerCompanies"));
        let   websiteId = process.env.REACT_APP_WEBSITE_ID;

        useEffect(() => {
            dispatch(settingsActions.getBannersAction({
                section: 'verify',
                params: {group_name:"footer-apps", request_from_front: true, website_id: websiteId}
            }));
        }, [dispatch, section, websiteId]);
        
        useEffect(() => {
            dispatch(settingsActions.getBannersAction({
                section:'footerCompanies',
                params: {group_name:"footer-companies", request_from_front: true, website_id: websiteId}
            }));
        }, [dispatch, section, websiteId]);

    return(
        <div className="apps_verify">
           <div className="apps_block">
            {
               footerVerify?.map((item,index)=>{ 
                return (
                    <div key={index}>
                      <a href={item.link_text} target='_blank' rel="noopener noreferrer">        
                         <img src={item.image_url} title={item.title} alt={item.name} />
                      </a>  
                    </div>
                )})
            }
               {/* <img src="/resources/images/social/apple.svg" title="Apple" alt="Apple" /> */}
               <div className="available_apps"> <FormattedMessage id='available_apps'/> </div>
           </div>
           <div className="checked_organizations">
               {
                   footerCompanies?.map((item,index)=>{
                       if (item.link) {
                            return(
                                <a href={item.link} target='_blank' rel="noopener noreferrer">
                                    <img src={item.image_url} title={item.title} alt={item.name} key={index} />
                                </a>
                            )
                       }
                       return(
                            <img src={item.image_url} title={item.title} alt={item.name} key={index} />
                       )
                   })
               }

               <div className="info_block">
                    <FormattedMessage id='info_block'/>
               </div>

            </div>
       </div>
    )
}
export default AppsVerify