import {apiRequest} from "../../service/service";

class HistoryAPI {
    getCasinoHistory = (params = {}) => {
        const url = `/v1/profile/history/casino`;
        const config = {
            method: 'get',
            url: url,
            params,
        };
        const result = apiRequest(config);
        return result;
    };
    getCasinoBonuses = (params = {}) => {
        const url = `/v1/profile/casino/bonus/wallets`;
        const config = {
            method: 'get',
            url: url,
            params,
        };
        const result = apiRequest(config);
        return result;
    };
    getCasinoSpins = (params = {}) => {
        const url = `/v1/profile/casino/bonus/spins`;
        const config = {
            method: 'get',
            url: url,
            params,
        };
        const result = apiRequest(config);
        return result;
    };
    getDepositHistory = (params = {}) => {
        const url = `/v1/profile/deposits`;
        const config = {
            method: 'get',
            url: url,
            params,
        };
        const result = apiRequest(config);
        return result;
    };
    getWithdrawalsHistory = (params = {}) => {
        const url = `/v1/profile/withdrawals`;
        const config = {
            method: 'get',
            url: url,
            params,
        };
        const result = apiRequest(config);
        return result;
    };

}

export default HistoryAPI;
