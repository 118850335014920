import React, {Fragment} from 'react';
import {connect} from "react-redux";
import {NotDesktop, Desktop} from "../../../helpers/devices";
import SearchPanel from "../../search-panel";
import SidebarBottom from "../../sidebar/sidebar-bottom";
import SidebarMiddle from "../../sidebar/sidebar-middle";

import "./sidebar.scss";
import menuActions from "../../../redux/menu/actions";



function Sidebar(props) {


    const { layout } = props;
    if(layout === 'promotion' || layout === 'error'){
        return false;
    }

    const {sidebar,toggleSidebar} = props;

    const handleClick = () => {
        toggleSidebar(!sidebar);
        sidebar ?
        document.body.classList.remove('menu_opened')
        : document.body.classList.add('menu_opened');
    };


    return (
        <Fragment>
            {
                layout === 'game' ? <Desktop>
                    <div className={`sidebar_menu ${sidebar ? 'sidebar_toggle' : ''}`}>
                        <div className="sidebar_inner">
                            <div className="inner_content">
                                <NotDesktop><SearchPanel/></NotDesktop>
                                <SidebarMiddle/>
                                <SidebarBottom/>
                            </div>
                        </div>
                        <button className="sidebar_btn icon_right" onClick={handleClick}><span className="burger_lines"></span>
                        </button>
                    </div>
                </Desktop> : <div className={`sidebar_menu ${sidebar ? 'sidebar_toggle' : ''}`}>
                    <div className="sidebar_inner">
                        <div className="inner_content">
                            <NotDesktop><SearchPanel/></NotDesktop>
                            <SidebarMiddle/>
                            <SidebarBottom/>
                        </div>
                    </div>
                    <button className="sidebar_btn icon_right" onClick={handleClick}>
                        <span className="burger_lines"></span>
                    </button>
                </div>
            }
        </Fragment>
        
        
    );
}

const mapStateToProps = ({Menu,Settings}) => {
    return (
        {
            sidebar: Menu.get('sidebar'),
            layout: Settings.get('layout')
        }
    )
};


const mapDispatchToProps = {
    toggleSidebar: menuActions.toggleSidebarAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
