import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import settingsActions from "../../../redux/settings/actions";

import './payments.scss';

const Payments = ({section}) => {
    const dispatch = useDispatch();
    const footerPayments = useSelector(({Settings}) => Settings.get("payments"));
    let    websiteId = process.env.REACT_APP_WEBSITE_ID;
    

    useEffect(() => {
        dispatch(settingsActions.getBannersAction({
            section : "payments",
            params  : {group_name:"footer-payments", request_from_front: true, website_id: websiteId}
        }));
    }, [dispatch, section, websiteId]);

    if (!footerPayments || !footerPayments.length)
        return false;

    return (
      
        <ul className="payments_list"> 
            {
              footerPayments.map((item,index) => {
                    if (item.link){
                        return(
                            <li key={index}>
                                <a href={item.link} target='_blank' rel="noopener noreferrer">
                                   <img src={item.image_url} title={item.title} alt={item.name} /> 
                                </a>
                            </li> 
                        )
                    }
                return (
                    <li key={index}>
                        <img src={item.image_url} title={item.title} alt={item.name} /> 
                    </li> 
                )
               }) 
            }
        </ul>
    )
};

export default Payments;