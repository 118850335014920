import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { Desktop, NotDesktop } from "../../../helpers/devices";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import Chat from "../../../components/controls/live-chat";
import actions from "../../../redux/help/actions";

import './bottom-menu.scss';



const BottomMenu = () => {
    const dispatch = useDispatch();

    const authToken = useSelector(({ Profile }) => Profile.get('authToken'));

    const layout = useSelector(({ Settings }) => Settings.get('layout'));

    if (layout === 'promotion') {
        return false;
    }



    const handleChatOpen = () => {
        dispatch(actions.openChat());
    };


    return (
        <Fragment>
            {
                authToken ?
                    <Fragment>
                        {
                            layout === 'game' ? <button className="help_btn icon_help" onClick={handleChatOpen} > <FormattedMessage id="help" /> </button> :
                                <Fragment>
                                    <Desktop>
                                        <button className="help_btn icon_help" onClick={handleChatOpen}>
                                            <FormattedMessage id="help" />
                                            {/* <NavLink className="help_btn icon_help" to="/help"><FormattedMessage id="help" /></NavLink> */}
                                        </button>
                                    </Desktop>
                                    <NotDesktop>
                                        <div className="bottom_menu">
                                            <ul className="menu_list">
                                                <li><NavLink to="/promotions/" className="icon_gift"><FormattedMessage id="promotions" /></NavLink></li>
                                                <li><NavLink to="/profile/" className="icon_profile"><FormattedMessage id="profile.title" /></NavLink></li>
                            
                                                <li><NavLink to="/profile/deposit/" className="icon_deposit"><FormattedMessage id="deposit.capitalize" /></NavLink></li>
                                                <li>
                                                    <button className="help_btn icon_help" onClick={handleChatOpen}>
                                                        <FormattedMessage id="help" />
                                                        {/* <NavLink className="help_btn icon_help" to="/help"><FormattedMessage id="help" /></NavLink> */}
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </NotDesktop>
                                </Fragment>
                        }
                    </Fragment> :
                      <button className="help_btn icon_help" onClick={handleChatOpen}>
                          <FormattedMessage id="help" />
                          {/* <NavLink className="help_btn icon_help" to="/help"><FormattedMessage id="help" /></NavLink> */}
                     </button>
            }
            <Chat />

        </Fragment>
    )
};

export default BottomMenu;
