import React from "react";
import { Link, useLocation } from 'react-router-dom';
import './header-profile.scss';

import {FormattedMessage} from "react-intl";
import {Desktop} from "../../helpers/devices";
import SidebarUserActions from "../sidebar/sidebar-user-actions";
import {kFormatter} from "../../helpers/utils";
import {useSelector} from "react-redux";
import {currencyList} from "../../config";

const HeaderProfile = (props) =>{
    const profileData = useSelector(({Profile}) => Profile.get('profileData'));
    let location = useLocation();
    const depositColor = location.pathname;
    if(!profileData){
        return false;
    }
 
    const {first_name,last_name,username,email} = profileData;

    const name = !first_name && !last_name && username ? username :
        !first_name && !last_name && email ? email :
            `${first_name} ${last_name}`;
    
    return (
        <div className="profile_block">
            <div className="user_block">
                <Link to="/profile" className="user_info">
                    <span className="user_image">
                        {
                            profileData.avatar ? (
                            <img src = {`${profileData.avatar}`} alt = '' title = ''/>
                            ) : <img src="/resources/images/avatar.jpg" alt="" title=""/>
                        }
                    </span>
                    <span className="name_balance">
                        <span className="user_name">{name}</span>
                        <span className="user_balance">{currencyList[profileData.currency_code]}{kFormatter(profileData.balance,profileData.bonus_balance)}</span>
                    </span>
                </Link>
                <Link className="deposit_btn icon_plus" to="/profile/deposit/"></Link>
            </div>
            <SidebarUserActions/>
            <Desktop>
                <Link className="deposit_btn" 
                style={ depositColor.includes("/deposit") ? {backgroundColor: "#ff472e",color:"#ffffff"}  :null } 
                to="/profile/deposit/"><span><FormattedMessage id="deposit"/></span>
                </Link>
            </Desktop>
            
        </div>
    )
};

export default HeaderProfile;