import { call, put, takeLatest, all, fork } from 'redux-saga/effects';
import Api from './api';
import actions from "./actions";
import modalsActions from "../modals/actions";
import menuActions from "../menu/actions";
import { detectMediaSize, formatCategoriesUrl, sortCategories } from "../../helpers/utils";
import { APP_URL } from "../../config";
const API = new Api();




function* getCategories() {
    yield takeLatest("GET_CATEGORIES", function* (action) {
        try {
            const result = yield call(API.getCategoriesList);

            if (result && result.status === 200) {
                const data = sortCategories(result.data.data);
                const sitemap = formatCategoriesUrl(data, 'alias', 'name');
                yield put(menuActions.setToSitemap(sitemap));
                yield put({ type: "SET_CATEGORIES", data });
                /*const category = yield select(getCategory);
                if(!category)
                yield put({type: "SELECT_CATEGORY"});*/
            }
        } catch (e) {
            console.log('e', e)
            /*const message = {
                title: "Error",
                message: e.text,
                type: "danger"
            };*/
        }
    });

}

function* getSubCategories() {
    yield takeLatest("GET_SUB_CATEGORIES", function* (action) {
        try {
            const result = yield call(API.getSubCategoriesList, action.data);
            if (result && result.status === 200) {
                yield put(actions.setSubCategoriesAction(result.data.data));
            }
        } catch (e) {
            console.log('e', e)
            /*const message = {
                title: "Error",
                message: e.text,
                type: "danger"
            };*/
        }
    });

}

function* selectCategory() {
    yield takeLatest(actions.SELECT_CATEGORY, function* (action) {
        try {
            yield put(actions.getProvidersAction({ categories: [action.data] }));

        } catch (e) {
            console.log('e', e)
            /*const message = {
                title: "Error",
                message: e.text,
                type: "danger"
            };*/
        }
    });

}

function* getProviders() {
    yield takeLatest("GET_PROVIDERS", function* (action) {
        try {
            yield put(actions.setProvidersAction(null));
            const result = yield call(API.getProvidersList, action.data);
            if (result && result.status === 200) {
                yield put(actions.setProvidersAction(result.data.data));
            }
        } catch (e) {
            /*const message = {
                title: "Error",
                message: e.text,
                type: "danger"
            };*/
        }
    });

}

function* searchGames() {
    yield takeLatest("SEARCH_GAMES", function* (action) {
        yield put(actions.setSearchedGamesAction(null));
        try {
            const { data } = action;
            const params = {
                ...data,
                sort_by: "play_count",
                channel_id: 1
            };
            const result = yield call(API.getGamesList, params);

            if (result && result.status === 200) {
                yield put(actions.setSearchTermAction(data.name));
                yield put(actions.setSearchedGamesAction(result.data.data));
            }
        } catch (e) {
            /*const message = {
                title: "Error",
                message: e.text,
                type: "danger"
            };*/
        }
    });

}

function* searchHomeGames() {
    yield takeLatest("SEARCH_HOME_GAMES", function* (action) {
        yield put(actions.setHomeSearchedGamesAction(null));
        try {
            const { data } = action;
            const params = {
                ...data,
                sort_by: "play_count",
                channel_id: 1
            };
            const result = yield call(API.getGamesList, params);

            if (result && result.status === 200) {
                //localStorage.setItem("auth_token",result.data.data.user.auth_token)
                yield put(actions.setHomeSearchedGamesAction(result.data.data));
            }
        } catch (e) {
            /*const message = {
                title: "Error",
                message: e.text,
                type: "danger"
            };*/
        }
    });

}

function* getGamesList() {

    yield takeLatest("GET_GAMES_LIST", function* (action) {
        try {
            const { data } = action;
            const { section = "games" } = data;
            if (data.action === "filter_games")
                yield put(actions.setGamesListAction({ action: data.action, data: null, section }));

            const params = {
                ...data.params,
                category: data.params.category !== 423 ? data.params.category : '',
                sort_by: "play_count",
                channel_id: 1,
            };

            const result = yield call(API.getGamesList, params);


            if (result && result.status === 200) {
                yield put(actions.setGamesListAction({ action: data.action, data: result.data.data, section }));
            }
        } catch (e) {
            console.log('e', e)
            /*const message = {
                title: "Error",
                message: e.text,
                type: "danger"
            };*/
        }
    });

}
var game_list = []

function* getGamesGroups() {
    yield takeLatest("GET_GAMES_GROUPS", function* (action) {
        try {
            yield put({ type: "SET_GAMES_GROUPS", data: { main: null, similar: null } });
            const { data } = action;
            const params = {
                ...data,
                category: data.category !== 423 ? data.category : '',
                sort_by: "play_count",
                channel_id: 1,
            };

            const { main, similar } = yield all({
                main: call(API.getGamesList, params),
                similar: call(API.getSimilarGames, { category: data.category, lang_id: params.lang_id })
            });

            if (main && main.status === 200) {  //  && similar && similar.status === 200
                if (main.data.data.length < 18) {
                    yield put({ type: "SET_SHOW_MORE", data: false })
                } else {
                    yield put({ type: "SET_SHOW_MORE", data: true })
                }

                if (main.data.data && main.data.data.length) {
                    if (game_list.length) {
                        const category = main.data.data[0].category;

                        const findCat = game_list.find(g => g.category === category);

                        if (!findCat) {
                            game_list = []
                        }
                    }

                    main.data.data.forEach(game => {
                        game_list.push(game);
                    });




                }
                yield put({ type: "SET_GAMES_GROUPS", data: { main: game_list, similar: similar.data.data } });
            }

        } catch (e) {
            yield put({ type: "SET_GAMES_GROUPS", data: { main: [], similar: [] } });
            console.log('e', e)
            /*const message = {
                title: "Error",
                message: e.text,
                type: "danger"
            };*/
        }
    });

}

function* startGame() {

    yield takeLatest("START_GAME", function* (action) {
        try {
            const { data } = action;
            const params = {
                ...data,
                exit_url: APP_URL,
                https: true
            };
            const result = yield call(API.startGame, params);
            const device = detectMediaSize();

            if (result && result.status === 200) {
                if (device === 'mobile') {
                    return window.location = result.data.data.url;
                }
                yield put(actions.setGameAction({ url: result.data.data.url, error: null }));
            }
        } catch (e) {
            yield put(actions.setGameAction({ url: null, error: e.message }));
            if (e.message === 'INSUFFICIENT_BALANCE')
                yield put(modalsActions.setDepositUiAction({ visible: true }));
            else
                yield put(modalsActions.setInfoUiAction({ visible: true, type: 'reject', description: e.message }));

        }
    });

}

function* profileSaga() {
    yield all([
        fork(searchGames),
        fork(searchHomeGames),
        fork(getCategories),
        fork(selectCategory),
        fork(getProviders),
        fork(getGamesList),
        fork(getGamesGroups),
        fork(startGame),
        fork(getSubCategories)
    ]);
}

export default profileSaga;