import React, {useEffect} from "react";
import settingsActions from "../../../redux/settings/actions";
import {useDispatch, useSelector} from "react-redux";



const Social = ({section})=>{
    const dispatch = useDispatch();
    const footerSocials = useSelector(({Settings}) => Settings.get('socials'));
    let websiteId = process.env.REACT_APP_WEBSITE_ID;
    
    useEffect(() => {
        dispatch(settingsActions.getBannersAction({
            section: 'socials',
            params: {group_name:"footer-socials", request_from_front: true, website_id: websiteId}
        }));
}, [dispatch, section, websiteId]);

   
    return(
        <ul className="social_list">
             {footerSocials?.map((item,index) => {
                 return (
                     <li key={index}>
                         <a href={`${item.link}`} target='_blank' rel="noopener noreferrer"  className={`icon_${item.link_text}`}> </a>
                     </li>
                 )
             })}
        </ul>
    )
}
export default Social