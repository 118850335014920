import {call, put, takeEvery, all, fork,select} from 'redux-saga/effects';
import api from './api';
import historyActions from './actions';

const API = new api();

 const getFilters = ({History},action) => {
     switch (action) {
         case "GET_CASINO_HISTORY" : {
             const { filters } = History.casinoHistory;
             const merge = {...filters,status_id: filters.status_id ? [filters.status_id] : null};
             return merge;
         }
         case "GET_CASINO_BONUSES" : {
             return History.casinoBonuses.filters;
         }
         case "GET_CASINO_SPINS" : {
             return History.casinoSpins.filters;
         }
         case "GET_DEPOSIT_HISTORY" : {
             return History.depositHistory.filters;
         }
         case "GET_WITHDRAWALS_HISTORY" : {
             return History.withdrawalsHistory.filters;
         }
         default:
             return History;
     }
};

function* casinoHistory() {
    yield takeEvery(historyActions.GET_CASINO_HISTORY, function* (action) {
        const filters = yield select(getFilters,"GET_CASINO_HISTORY");
        try {
            yield put(historyActions.setCasinoHistory({data:null, dataCount: 0}));
            const response = yield call(API.getCasinoHistory, filters);
            if (response && response.status === 200) {
                const {data} = response.data;
                yield put(historyActions.setCasinoHistory({data, dataCount: response.headers['x-total-count']}));
            }
        } catch (e) {
            yield put(historyActions.setCasinoHistory({data:[], dataCount: 0}));
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* casinoHistoryBonus() {
    yield takeEvery(historyActions.GET_CASINO_BONUSES, function* (action) {
        const filters = yield select(getFilters,"GET_CASINO_BONUSES");
        try {
            yield put(historyActions.setCasinoBonuses({data:null, dataCount: 0}));
            const response = yield call(API.getCasinoBonuses, filters);
            if (response && response.status === 200) {
                const {data} = response.data;
                yield put(historyActions.setCasinoBonuses({data, dataCount: response.headers['x-total-count']}));
            }
        } catch (e) {
            yield put(historyActions.setCasinoBonuses({data:[], dataCount: 0}));
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* casinoHistorySpins() {
    yield takeEvery(historyActions.GET_CASINO_SPINS, function* (action) {
        const filters = yield select(getFilters,"GET_CASINO_SPINS");
        try {
            yield put(historyActions.setCasinoSpins({data:null, dataCount: 0}));
            const response = yield call(API.getCasinoSpins, filters);
            if (response && response.status === 200) {
                const {data} = response.data;
                yield put(historyActions.setCasinoSpins({data, dataCount: data.length}));
            }
        } catch (e) {
            yield put(historyActions.setCasinoSpins({data:[], dataCount: 0}));
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* depositHistory() {
    yield takeEvery(historyActions.GET_DEPOSIT_HISTORY, function* (action) {
        const filters = yield select(getFilters,"GET_DEPOSIT_HISTORY");
        try {
            yield put(historyActions.setDepositHistory({data:null, dataCount: 0}));
            const response = yield call(API.getDepositHistory, filters);
            if (response && response.status === 200) {
                const {data} = response.data;
                yield put(historyActions.setDepositHistory({data, dataCount: response.headers['x-total-count']}));
            }
        } catch (e) {
            yield put(historyActions.setDepositHistory({data:[], dataCount: 0}));
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* withdrawalsHistory() {
    yield takeEvery(historyActions.GET_WITHDRAWALS_HISTORY, function* (action) {
        const filters = yield select(getFilters,"GET_WITHDRAWALS_HISTORY");
        try {
            yield put(historyActions.setWithdrawalsHistory({data:null, dataCount: 0}));
            const response = yield call(API.getWithdrawalsHistory, filters);
            if (response && response.status === 200) {
                const {data} = response.data;
                yield put(historyActions.setWithdrawalsHistory({data, dataCount: response.headers['x-total-count']}));
            }
        } catch (e) {
            yield put(historyActions.setWithdrawalsHistory({data:[], dataCount: 0}));
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* setFilters() {
    yield takeEvery(historyActions.SET_FILTERS, function* (action) {
        try {
            const {type} = action.data;
            switch (type) {
                case "casinoHistory" : {
                    return  yield put(historyActions.getCasinoHistory());
                }
                case "casinoBonuses" : {
                    return yield put(historyActions.getCasinoBonuses());
                }
                case "casinoSpins" : {
                    return yield put(historyActions.getCasinoSpins());
                }
                case "depositHistory" : {
                    return yield put(historyActions.getDepositHistory());
                }
                case "withdrawalsHistory" : {
                    return yield put(historyActions.getWithdrawalsHistory());
                }
                default:
                    return History;
            }



        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* historySaga() {
    yield all([
        fork(casinoHistory),
        fork(casinoHistoryBonus),
        fork(casinoHistorySpins),
        fork(depositHistory),
        fork(withdrawalsHistory),
        fork(setFilters)
    ]);
}

export default historySaga;